<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div class="hidden-menu" style="display: none;">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
             <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="ps-prduct_box">
            <template v-for="(product, index) in getcartList" :key="index">
              <div class="produtDeatilsDivFloat">
                <ProductCard
                  :product="product"
                  :web_admin_url="web_admin_url"
                  @addToCart="addtocart($event)"
                  :cart_ajax="cart_ajax"
                  :cart_product_url_key="cart_product_url_key"
                  :currency="currency"
                  :fevare_required="false"
                  :feverate_status="false"
                  :translationloaded="translationloaded"
                  :translate="translate"
                >
                </ProductCard>
              </div>
            </template>
          </div>
          <div class="w-100" v-if="getcartList.length < 1">
            <EmptyItem></EmptyItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import ProductCard from "../components/ProductCard";
import { mapActions, mapGetters } from "vuex";
import Config from "@/config";
import EmptyItem from "@/components/EmptyItem";

export default {
  name: "MyCart",
  components: {
    Breadcrumb,
    SideBar,
    ProductCard,
    EmptyItem,
  },
   props:[
     'translationloaded',
     'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/my-cart",
          name: "My Cart",
        },
      ],
      web_admin_url: "",
      cart_ajax: "closed",
      cart_product_url_key: "",
      loaderColor: "red",
      loaderStyle: {
        width: "40px",
      },
      currency: "",
      guest_id: "",
      cust_id: null,
    };
  },

  methods: {
    ...mapActions(["getCustomerWishlist"]),
    addtocart(productInfo) {
      let urlKey = productInfo.url_key;
      let productQty = productInfo.product_qty;
      let $this = this;
      $this.cart_ajax = "open";
      $this.cart_product_url_key = productInfo.url_key;
      let payload = {
        cusId: $this.cust_id != null ? $this.cust_id : "",
        guestId: $this.cust_id == null ? $this.guest_id : "",
        pincode: $this.pincode,
        urlKey: urlKey,
        productQty: productQty,
        vendorUrlkey: $this.vendor_url_key,
      };

      $this.$store
        .dispatch("addToCart", { payload: payload, toast: $this.$toast })
        .then(function() {
          // console.log(response);
          $this.cart_ajax = "closed";
          $this.cart_product_url_key = "";
          $this.customerCart = $this.$store.state.customer_cart;
          $this.$emit("cartUpdated", $this.customerCart);
        });
    },
     
  },
  mounted() {
    this.vendor_url_key = Config.VENDOR_URL_KEY;
    this.web_admin_url = Config.WEB_ADMIN_URL;
    this.currency = Config.CURRECNCY;
    this.guest_id = localStorage.getItem("guestId");
    this.cust_id = localStorage.getItem("custId");
   
  },
  computed: {
    ...mapGetters(["getcartList"]),
  },
};
</script>
<style scoped>
.ps-product {
  margin-left: 7px;
  margin-right: 7px;
}
</style>
